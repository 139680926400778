
































































import { Component, Prop, Vue } from 'vue-property-decorator'
import {
    apiDistributionAdjustLevelInfo, //分销等级
    apiDistributionAdjustLevel
} from '@/api/distribution/distribution'
@Component
export default class UserSelect extends Vue {
    @Prop({ default: '25vh' }) top!: string | number //弹窗的距离顶部位置
    @Prop({ default: 1 }) id!: string | number //弹窗的距离顶部位置

    /** S Data **/
    visible: any = false //是否显示

    distributionList: any = {
        user: {
            level_name: '默认等级(1级)',
            nickname: '',
            sn: ''
        }
    } //分销等级列表

    form: any = {
        level_id: ''
    }

    /** E Data **/

    /** S Method **/

    // 分销等级
    getDistributionAdjustLevelInfo() {
        apiDistributionAdjustLevelInfo({ user_id: this.id }).then(
            (res: any) => {
                this.distributionList = res
            }
        )
    }

    // 点击取消
    handleEvent(type: 'cancel' | 'confirm') {
        if (type === 'cancel') {
            this.close()
        }
        if (type === 'confirm') {
            if (this.form.level_id == '') {
                return this.$message.error('请选择分销等级！')
            }
            this.form.user_id = this.id
            apiDistributionAdjustLevel({
                ...this.form
            }).then(() => {
                this.$emit('update', '')
            })
            this.close()
        }
    }

    // 打开弹窗
    onTrigger() {
        this.getDistributionAdjustLevelInfo()
        this.visible = true
    }

    // 关闭用户选择弹窗
    close() {
        this.visible = false
    }

    /** E Method **/
}
