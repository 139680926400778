









































































































































































































































import { Component, Vue } from 'vue-property-decorator'
import LsPagination from '@/components/ls-pagination.vue'
import LsDialog from '@/components/ls-dialog.vue'
import { RequestPaging } from '@/utils/util'
import DatePicker from '@/components/date-picker.vue'
import OpenStore from '@/components/marketing/open-store.vue'
import ExportData from '@/components/export-data/index.vue'
import DistributeGrade from '@/components/marketing/distribute-grade.vue'
import {
    apiDistributionMemberLists, //列表
    apiDistributionfreeze, //冻结解冻
    apiDistributionGradeLists //分销等级
} from '@/api/distribution/distribution'
@Component({
    components: {
        LsPagination,
        DatePicker,
        LsDialog,
        OpenStore,
        DistributeGrade,
        ExportData
    }
})
export default class DistributionGoods extends Vue {
    /** S Data **/

    apiDistributionMemberLists = apiDistributionMemberLists

    distributionList: any = []

    goodsSearchData = {
        user_info: '',
        level_id: '', //分销会员等级id
        is_freeze: '',
        start_time: '',
        end_time: ''
    }

    options = [
        { value: '', label: '全部' },
        { value: '0', label: '正常' },
        { value: '1', label: '冻结' }
    ]

    pager = new RequestPaging()

    /** E Data **/

    /** S Method **/

    // 获取分销列表
    getListData(page?: number): void {
        page && (this.pager.page = page)
        this.pager.request({
            callback: apiDistributionMemberLists,
            params: {
                ...this.goodsSearchData
            }
        })
    }

    // 分销等级
    getGoodsOtherList() {
        apiDistributionGradeLists({}).then((res: any) => {
            this.distributionList = res.lists
        })
    }

    //  冻结或者解冻
    frozen(id: number): void {
        apiDistributionfreeze({
            user_id: id
        }).then(() => {
            this.resetgoodsSearchData()
        })
    }

    // 重置
    resetgoodsSearchData() {
        Object.keys(this.goodsSearchData).map(key => {
            this.$set(this.goodsSearchData, key, '')
        })
        this.getListData()
    }

    // 去用户详情
    toUserDetail(id: any) {
        this.$router.push({
            path: '/user/user_details',
            query: {
                id: id
            }
        })
    }

    /** E Method **/

    created() {
        this.getListData()
        this.getGoodsOtherList()
    }
}
